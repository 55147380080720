.markdown-preview-grid div:nth-child(2) {
  background-color: #e9e8e8;
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.markdown-preview {
  height: 100%;
  margin-top: -1em;
}

.markdown-editor {
  height: 100%;
}